<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <div>
      <Breadcrumbs />

      <v-menu
        id="columns-menu"
        v-model="showColumnsMenu"
        :close-on-content-click="false"
        :nudge-width="450"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-5 mr-5" style="display: inline-block;" v-bind="attrs" v-on="on" icon
            ><v-icon color="grey">mdi-cog-outline</v-icon></v-btn
          >
        </template>
        <v-card>
          <v-chip
            style="margin: 15px"
            :color="allColumnsDisplayed ? 'primary' : 'grey'"
            @click="toggleAllColumns"
            text-color="white"
          >
            <v-avatar v-if="allColumnsDisplayed" left>
              <v-icon>mdi-check</v-icon>
            </v-avatar>
            {{ $t("filters.allfilters") }}
          </v-chip>

          <v-row no-gutters>
            <v-col cols="6">
              <v-list>
                <v-list-item
                  v-for="(column, index) in columns
                    .filter((f) => !f.hidden)
                    .slice(0, columns.length / 2)"
                  :key="index"
                >
                  <template>
                    <v-chip
                      class="column-chip"
                      :color="column.active ? 'primary' : 'grey'"
                      @click="toggleColumn(column)"
                      text-color="white"
                    >
                      <v-avatar v-if="column.active" left>
                        <v-icon>mdi-check</v-icon>
                      </v-avatar>
                      {{ column.title }}
                    </v-chip>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col no-gutters cols="6">
              <v-list>
                <v-list-item
                  v-for="(column, index) in columns
                    .filter((f) => !f.hidden)
                    .slice(columns.length / 2, columns.length)"
                  :key="index"
                >
                  <template>
                    <v-chip
                      class="column-chip"
                      :color="column.active ? 'primary' : 'grey'"
                      @click="toggleColumn(column)"
                      text-color="white"
                    >
                      <v-avatar v-if="column.active" left>
                        <v-icon>mdi-check</v-icon>
                      </v-avatar>
                      {{ column.title }}
                    </v-chip>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-btn icon absolute top right @click="showColumnsMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card>
      </v-menu>

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
        maxlength="250"
      ></v-text-field>
      
      <div class="top-right-fab">
        <v-btn
          v-if="userFunctions"
          :disabled="userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION) == null"
          class="techno-green"
          fab
          @click="create" 
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <v-container fluid v-if="tabulatorColumns">

      <ReportsDatatable 
            :datatableName="$t(SITE_REPORT_STATUS_TEXT[SITE_REPORT_STATUS.DRAFT])"
            :color="SITE_REPORT_STATUS_COLOR[SITE_REPORT_STATUS.DRAFT].text"
            :reportStatus="0"
            :expandedByDefault="true"
            :columns="tabulatorColumns"
            :generalSearch="search"
            @editReport="editReport" 
            @onShowMap="onShowMap"
       />

      <ReportsDatatable 
            :datatableName="$t(SITE_REPORT_STATUS_TEXT[SITE_REPORT_STATUS.VALIDATION])"
            :color="SITE_REPORT_STATUS_COLOR[SITE_REPORT_STATUS.VALIDATION].text"
            :reportStatus="1"
            :expandedByDefault="true"
            :columns="tabulatorColumns"
            :generalSearch="search"
            @editReport="editReport" 
            @onShowMap="onShowMap"
       />

      <ReportsDatatable 
            :datatableName="$t(SITE_REPORT_STATUS_TEXT[SITE_REPORT_STATUS.VALIDATED])"
            :color="SITE_REPORT_STATUS_COLOR[SITE_REPORT_STATUS.VALIDATED].text"
            :reportStatus="2"
            :expandedByDefault="true"
            :columns="tabulatorColumns"
            :generalSearch="search"
            @editReport="editReport" 
            @onShowMap="onShowMap"
       />

       <ReportsDatatable 
            :datatableName="$t(SITE_REPORT_STATUS_TEXT[SITE_REPORT_STATUS.CANCELED])"
            :color="SITE_REPORT_STATUS_COLOR[SITE_REPORT_STATUS.CANCELED].text"
            :reportStatus="3"
            :expandedByDefault="true"
            :columns="tabulatorColumns"
            :generalSearch="search"
            @editReport="editReport" 
            @onShowMap="onShowMap"
       />
    </v-container>

    <v-dialog v-model="showMap">
      <v-card>
        <v-card-title>
          {{ $t("reportInfo.site_address") }}
          <a v-if="marker" :href="'https://www.google.com/maps/dir/?api=1&destination=' + marker.position.toString().replace('(','').replace(')','')"  target="_blank" style="margin-left:15px;">{{ $t("report.get_directions") }}</a>
          <v-icon style="position:absolute;top:15px;right:15px;" @click="showMap = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div id="map" style="height:75vh;width:100%;"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import ReportService from "@/services/report.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import ReportsDatatable from "@/components/ReportsDatatable"
import { FUNCTIONS, SITE_REPORT_STATUS, SITE_REPORT_STATUS_TEXT, SITE_REPORT_STATUS_COLOR } from '@/enums'
import i18n from "@/i18n";

/*global google*/

export default {
  components: {
    Breadcrumbs,
    ReportsDatatable
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
      userFunctions: (state) => state.userFunctions,
      countries: (state) => state.countries,
    }),
    allColumnsDisplayed() {
      for (let column of this.columns) {
        if (column.field !== "actions" && !column.active) {
          return false;
        }
      }
      return true;
    }
  },
  data() {
    return {
      SITE_REPORT_STATUS:SITE_REPORT_STATUS,
      SITE_REPORT_STATUS_TEXT:SITE_REPORT_STATUS_TEXT,
      SITE_REPORT_STATUS_COLOR:SITE_REPORT_STATUS_COLOR,
      showColumnsMenu: false,
      locale: this.$i18n.locale,
      FUNCTIONS: FUNCTIONS,
      selectedReport: null,
      showMap: false,
      map: null,
      marker: null,
      tabulatorColumns: null,
      generalSearch: "",
      isSearching: false,
      search: "",
      columns: [
        {
          field: "formattedDealerName",
          title: i18n.t("filters.dealer"),
          headerSort: true,
          active: true,
        },
        {
          field: "formattedCustomerName",
          title: i18n.t("reportInfo.client"),
          headerSort: true,
          active: true,
        },
        {
          field: "formattedAddress",
          title: i18n.t("reportInfo.site_address"),
          widthGrow: 2,
          headerSort: true,
          active: true,
          formatter: "html",
        },
        {
          field: "formattedClientAddress",
          title: i18n.t("reportInfo.client_address"),
          headerSort: true,
          active: false,
        },
        {
          field: "formattedCreatedAt",
          title: i18n.t("report.created_at"),
          headerSort: true,
          active: false,
        },
        {
          field: "referenceNumber",
          title: i18n.t("report.refnum"),
          headerSort: true,
          active: true,
        },
        {
          field: "formattedInstallationStart",
          title: i18n.t("report.installation_start"),
          headerSort: true,
          active: true,
        },
        {
          field: "formattedInstallers",
          title: i18n.t("summary.siteinstaller"),
          headerSort: true,
          active: true,
        },
        {
          field: "installationPriority",
          title: i18n.t("report.priority"),
          width: 100,
          headerSort: true,
          active: true,
          hozAlign: "center",
          vertAlign: "middle",
          editor: "number",
          editorParams:{
              min: 0,
              max: 9,
              step: 1,
              selectContents: true,
          },
          validator: ["min:0", "max:9", "numeric"],
          formatter: function (cell) {
              let value = cell.getValue();
              if (value == 'null') {
                value = null;
              }
              return value;
          }
        },
        {
          field: "actions",
          title: "",
          width: 60,
          headerSort: false,
          active: true,
          hidden: true,
          formatter: "html",
          hozAlign: "center"
        },
      ]
    };
  },
  mounted() {
    console.log('Reports mounted')

    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("documentRequests.dr_fieldreports"), href: "/reports" },
    ]);

    let displayedColumnKeys = localStorage.getItem('displayedColumnKeys');
    console.log('displayedColumnKeys', displayedColumnKeys)

    if (displayedColumnKeys) {
      displayedColumnKeys = displayedColumnKeys.split(',');
      for (let column of this.columns) {
        column.active = column.field == 'actions' || displayedColumnKeys.find(k => column.field == k) != undefined;
      }
    }
    this.updateTabulatorColumns();
  },
  created: function() {
    console.log('Reports created')
  },
  beforeDestroy() {
    console.log('Reports unmounted')
  },
  watch: {
    showColumnsMenu() {
      this.updateTabulatorColumns();
    },
  },
  methods: {
      ...mapMutations({
      openToast: 'openToast',
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      logout: 'logout'
    }),
    async create() {
      let data = { userId: this.currentUser.id, locale: this.locale };
      let response = await ReportService.createReport(data);
      if (response.status == 200) {
        this.$router.push('/report/' + response.data.report.id);
      }
      else {
        this.openToast({ message: response, duration: 15000, color: 'red'});
      }
    },
    updateTabulatorColumns() {
      let displayedColumns = this.columns.filter(c => c.active);
      this.tabulatorColumns = JSON.parse(JSON.stringify(displayedColumns, null, 2));

      let displayedColumnKeys = this.columns.filter(c => c.active && c.field != 'actions').map(c => c.field);
      localStorage.setItem('displayedColumnKeys', displayedColumnKeys);
    },
    toggleAllColumns() {
      let allColumnsDisplayed = !this.allColumnsDisplayed;

      this.columns.forEach((column) => {
        if (column.field !== "actions")
          column.active = allColumnsDisplayed;
      });
    },
    toggleColumn(column) {
      column.active = !column.active;
    },
    editReport(report) {
      
      console.log('Edit report', report)
      this.selectReport(report);
    },
    selectReport(report) {

      console.log('Select report', report)
      this.$router.push("/report/" + report.id);
    },
    async onShowMap(report) {
      this.selectedReport = report;
      this.showMap = true;

      if (!this.map) {
        setTimeout(() => { 
          this.initMap();
         }, 250);
      }
      else {
        this.updateMap();
      }
    },
    // Initialize and add the map
    initMap() { // eslint-disable-line

      // Fallback geolocation is TP headquarter
      let point = { lat: 46.11118448049068, lng: -71.2913423841532 };

      let report = this.selectedReport;
      if (report.siteAddressLatitude != null && report.siteAddressLongitude != null) {
        point = { lat: report.siteAddressLatitude, lng: report.siteAddressLongitude };
      }
      
      this.initMapWithPoint(point);
    },
    initMapWithPoint(point) {
      let mapElement = document.getElementById("map");
      console.log('mapElement', mapElement)
      this.map = new google.maps.Map(mapElement, {
        zoom: 10,
        center: point,
        gestureHandling: 'greedy'
      });

      this.marker = new google.maps.Marker({
        position: point,
        map: this.map,
      });
    },
    async updateMap() {
        let point = { lat: this.selectedReport.siteAddressLatitude, lng: this.selectedReport.siteAddressLongitude };

        if (this.marker != null) {
          this.marker.setMap(null);
        }
 
        this.marker = new google.maps.Marker({
            position: point,
            map: this.map
        });
        this.map.setCenter(point);
    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      this.search = this.generalSearch;
    },
  },
};

</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.inlineblock {
  display: inline-block;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
td.text-start {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal !important;
}

#columns-menu {
  overflow:hidden; 
}

#filters-menu{
  overflow:hidden; 
}
#btn-add{
  background-color:#009f4d;
}
th, td {
  border-right: 1px solid #f1f1f1;
}
.v-breadcrumbs {
  display: inline-block;
}
</style>