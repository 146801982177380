<template>
  <div>
    <transition name="fade">
      <div>
        <v-row class="py-5">
          <v-col cols="12">
            <v-card class="border10">
              <v-row class="pa-4 pb-8">
                <h4 style="vertical-align: center" class="ml-4">
                  <span label class="ma-3" :style="{'color': color}">
                    {{ datatableName }}
                  </span>
                </h4>
                <v-spacer></v-spacer>
              </v-row>
              <v-divider></v-divider>

             
              <v-expand-transition>
                <div v-show="expandDatatable">
                  <v-row v-if="reloadingReports">
                    <v-col cols="12">
                      <v-card :loading="true" :elevation="0">
                        <v-skeleton-loader
                          type="table-heading, divider, list-item-two-line, list-item-avatar, list-item-avatar, list-item-avatar, divider, table-tfoot"
                        ></v-skeleton-loader>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card-text v-show="!reloadingReports">
                    <div class="reports-table" ref="table"></div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </transition>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
// import "tabulator-tables/dist/css/tabulator.min.css";
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";

import { mapActions, mapMutations, mapState } from "vuex";
import ReportService from "@/services/report.service";
import { getLanguage, getLanguageWithoutRegion, getTabulatorLangsObject, displayPaginationInfo } from "@/utils/utils";
import constants from "@/constants";
import authHeader from "@/services/auth-header";
import { SITE_REPORT_STATUS } from '@/enums'
import i18n from "@/i18n";

var scope = null;
var tables = {};

function openReport(reportId) { // eslint-disable-line
  scope.$router.push('/report/' + reportId)
}
window.openReport = openReport;

function openMap(reportId) { // eslint-disable-line
  scope.openMap(reportId);
}
window.openMap = openMap;

export default {
  name: "ReportsDatatable",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    reportStatus: {
      type: Number,
      default: 0,
    },
    datatableName: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "green",
    },
    expandedByDefault: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
            return []
        }
    },
    generalSearch: {
      type: String,
      default: "",
    }
  },
  watch: {
    columns() {
      tables[this.reportStatus].setColumns(this.getColumns);
      tables[this.reportStatus].refreshFilter();
    },
    generalSearch() {
      tables[this.reportStatus].refreshFilter();
    }
  },
  data() {
    return {
      expandDatatable: false,
      reports: [],
      reloadingReports: false,
      totalCount: null
    };
  },
  methods: {
    ...mapMutations([
      'showErrorMessage'
    ]),
    ...mapActions({
    }),
    savePriority(reportId, installationPriority) {
      var newInstallationPriority = installationPriority;

      // Put as null when 0
      if (newInstallationPriority == 0 || newInstallationPriority == 'null') {
        newInstallationPriority = null;
      }

      this.saveReportPriority({
        reportId: reportId,
        installationPriority: newInstallationPriority,
      })
      .then((resp) => {
        console.log("Success changing priority", resp);
      })
      .catch((err) => {
        console.log("Error changing priority", err);
      });
    },
    editReport(item) {
      console.log("Edit report", item);
      this.$emit("editReport", item);
    },
    async saveReportPriority(data) {
      console.log("Saving report priority", data.reportId, data.installationPriority)
      return await ReportService.saveReportPriority(data.reportId, data.installationPriority);
    },
    openMap(reportId) {
      for (let tableKey in tables) {
        let table = tables[tableKey];
        let report = table.getData().find(r => r.id == reportId);
        console.log('report', report)
        if (report) {
          this.$emit('onShowMap', report)
          break;
        } 
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    getColumns() {

      // Si le rapport n'est pas en draft, enlever l'editeur de cellule
      let columns = JSON.parse(JSON.stringify(this.columns, null, 2));
      if (this.reportStatus != SITE_REPORT_STATUS.DRAFT) {
        for (let column of columns) {
          delete column.editor;
          delete column.editorParams;
        }
      }

      return columns;
    }
  },
  mounted() {
    scope = this;
    var localScope = this;

    let columnsLangData = { 
      "formattedDealerName": i18n.t("filters.dealer"),
      "formattedCustomerName": i18n.t("reportInfo.client"),
      "formattedAddress": i18n.t("reportInfo.site_address"),
      "formattedClientAddress": i18n.t("reportInfo.client_address"),
      "formattedCreatedAt": i18n.t("report.created_at"),
      "formattedInstallationStart": i18n.t("report.installation_start"),
      "formattedInstallers": i18n.t("summary.siteinstaller"),
      "installationPriority": i18n.t("report.priority"),
      "referenceNumber": i18n.t("report.refnum")
    };

    tables[this.reportStatus] = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      columns: this.getColumns,
      persistence: { sort: true, filter: true, columns: false },
      persistenceID: "persistenceReport" + this.reportStatus,
      pagination: true,
      paginationSize: 10,
      paginationSizeSelector: [10, 25, 50, 100],
      paginationMode: "remote",
      paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, localScope),
      filterMode: "remote",
      sortMode: "remote",
      ajaxURL: constants.API_URL + `user/webReports/${this.reportStatus}`,
      ajaxConfig: {
        method: "POST",
        headers: authHeader(),
      },
      ajaxContentType:{
        headers:{
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: function(url, config, params) {
          let data = {
            language: getLanguage(),
            generalSearch: localScope.generalSearch,
            columns: localScope.columns.filter(c => c.field != 'actions').map(c => c.field)
          };
          Object.assign(params, data);
          return JSON.stringify(params);
        },
      },
      ajaxResponse: function (url, params, response) {
        console.log('response', response, url, params)

        for (let report of response.data) {

          report.formattedAddress = `<span class="report-address">${report.formattedAddress ? report.formattedAddress : ''}</span>`;
          if (report.siteAddressLongitude != null && report.siteAddressLatitude != null) {
            report.formattedAddress += `<span class="mdi mdi-map-marker datatable-marker" onclick="openMap(${report.id})"></span>`;
          }

          report.actions = `<span class="mdi mdi-pencil datatable-pencil" onclick="openReport(${report.id})"></span>`;
        }

        localScope.totalCount = response.totalCount;

        return response;
      },
      locale: getLanguageWithoutRegion(getLanguage()),
      langs: getTabulatorLangsObject(i18n, columnsLangData)
    });
    tables[this.reportStatus].on("cellEdited", function(cell){
      localScope.savePriority(cell.getData().id, cell.getValue())
    });

    if (this.expandedByDefault) {
      this.expandDatatable = true;
    }
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.header-text {
  line-height: 20px;
}
.priority-badge {
  float: right;
  background-color: #e8e8e8;
  width: 20px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  border-radius: 50%;
}

.column-chip {
  width: 225px;
}
</style>
